import {
  ClientStatus,
  ICompSummaryAndEntry,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  getCompSubTitleHome,
  getCompTitleHome,
} from '@chancer/common/lib/utils/CompetitionUtils';
import {MILLISECONDS_IN_HOUR} from '@chancer/common/lib/utils/DateUtils';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {CompPlayers} from '../Status/CompPlayers';
import {CompStatus} from '../Status/CompStatus';
import {LabelBadge} from '../Status/LabelBadge';
import {
  COLOR_DARK,
  COLOR_GREY_8,
  COLOR_LIGHT,
  COLOR_YELLOW,
  FONT_COPY,
  FONT_COPY_BOLD,
  FONT_TITLE,
} from '../Styles/DesignSystem-chancer';
import {LiveScore} from '../LiveScore/LiveScore';

interface IProps {
  competition: ICompSummaryAndEntry;
  outstandingQuestions: number;
  clientStatus: ClientStatus;
}

export const CompDetails: React.FC<IProps> = (props) => {
  const summary = props.competition.summary;
  const showLiveScores =
    summary.liveScore !== undefined &&
    summary.liveScore.homeName &&
    summary.liveScore.awayName &&
    summary.liveScore.homeScore !== undefined &&
    summary.liveScore.awayScore !== undefined;

  return (
    <View style={styles.container}>
      <View style={styles.statusContainer}>
        {((props.competition.entry === null &&
          props.clientStatus === ClientStatus.OPEN) ||
          props.outstandingQuestions > 0) && (
          <LabelBadge
            style={styles.newQuestions}
            label={`${
              props.competition.entry !== null ||
              props.clientStatus !== ClientStatus.OPEN
                ? `${props.outstandingQuestions} `
                : ''
            }NEW`}
            labelColor={COLOR_DARK}
            backgroundColor={COLOR_YELLOW}
          />
        )}
        <CompStatus
          startTime={props.competition.summary.starts}
          status={props.clientStatus}
          featured={true}
          questionsInPlay={
            props.competition.summary.inPlayQuestions?.length > 0
          }
          showDurationFromMs={MILLISECONDS_IN_HOUR * 2}
          isLive={props.competition.summary.liveScore?.isLive}
        />
        <CompPlayers
          withIcon={true}
          count={props.competition.summary.entriesCount}
        />
      </View>
      {showLiveScores ? (
        <View style={styles.liveScore}>
          <LiveScore
            liveScore={summary.liveScore}
            nameStyle={styles.liveScoreNameText}
            scoreStyle={styles.longScoreScoreText}
          />
        </View>
      ) : (
        <>
          <Text style={styles.title}>
            {getCompTitleHome(props.competition.summary)}
          </Text>
          <Text style={styles.subTitle}>
            {getCompSubTitleHome(props.competition.summary)}
          </Text>
        </>
      )}
      <View style={styles.logosContainer}>
        {props.competition.summary.strapline && (
          <Text style={styles.strapline}>
            {props.competition.summary.strapline}
          </Text>
        )}
        <Avatar
          style={styles.logo}
          imageUrl={props.competition.summary.logo.image?.url}
          highlightWidth={0}
          radius={7}
          size={48}
        />
        {props.competition.summary.sponsor?.image?.url && (
          <Avatar
            style={styles.logo}
            imageUrl={props.competition.summary.sponsor.image.url}
            highlightWidth={0}
            radius={7}
            size={48}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  statusContainer: {
    marginRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  newQuestions: {
    marginRight: 8,
  },
  title: {
    marginTop: 8,
    color: COLOR_LIGHT,
    fontFamily: FONT_TITLE,
    fontSize: 24,
  },
  subTitle: {
    marginTop: 4,
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 16,
  },
  logosContainer: {
    marginTop: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  strapline: {
    flex: 1,
    flexGrow: 1,
    color: COLOR_GREY_8,
    fontFamily: FONT_COPY,
    fontSize: 14,
  },
  logo: {
    marginLeft: 16,
  },
  liveScore: {
    height: 64,
    marginTop: 8,
  },
  liveScoreNameText: {
    color: COLOR_LIGHT,
    fontFamily: FONT_COPY_BOLD,
    fontSize: 18,
  },
  longScoreScoreText: {
    color: COLOR_LIGHT,
    fontSize: 18,
    fontFamily: FONT_COPY_BOLD,
  },
});
