import React, {ComponentType} from 'react';
import {Observable} from 'rxjs';

import {
  ClientStatus,
  IVendorDetails,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {EnterCompStatus} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseChallengeGroup,
  TFirebaseCompEntry,
  TFirebaseCompSummary,
  TFirebaseLeaderboard,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  LeaderboardType,
  TLeader,
  TLeaderboardSummaryAndLeaders,
  TMediaEntry,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getChallengedId,
  getChallengerId,
} from '@chancer/common/lib/utils/ChallengeUtils';
import {applyPropClassName} from '@chancer/common/lib/utils/ComponentUtils';
import {ChallengeButton} from '@chancer/components/lib/Challenges/ChallengeButton';
import {LazyChallengeButton} from '@chancer/components/lib/Challenges/LazyChallengeButton';
import {CompVendorListItem} from '@chancer/components/lib/CompVendorList/CompVendorListItem';
import {LeaderboardImage} from '@chancer/components/lib/Leaderboard/LeaderboardImage';
import {LeaderboardSummary} from '@chancer/components/lib/Leaderboard/LeaderboardSummary';
import {MultiGameLeaderboardSummary} from '@chancer/components/lib/Leaderboard/MultiGameLeaderboardSummary';
import {SectionSubTitle} from '@chancer/components/lib/SectionSubTitle/SectionSubTitle';
import {MediaView} from '../../Core/Media/MediaView';

import './Latest.scss';

interface IProps {
  className?: string;
  challengesEnabled: boolean;
  vendor: IVendorDetails;
  compSummary: TFirebaseCompSummary;
  status: ClientStatus;
  players: TLeader[];
  currentPlayer: TLeader | null;
  currentUser: TFirebaseUser;
  currentUserEntry: TFirebaseCompEntry | null;
  entryStatus: EnterCompStatus;
  entriesCount: number;
  compQuestions: TFirebaseQuestion[] | null;
  leaderboardConfig: TFirebaseLeaderboard;
  followingMap: {[key: string]: boolean};
  lastVerifiedCompId: string;
  vips: TFirebaseUser[];
  challengesWithUnreads: {
    challenge: TFirebaseChallengeGroup;
    badge: number;
  }[];
  getUserById: (userId: string) => Observable<TFirebaseUser>;
  getMultiGameLeaderboardSummaryById: (
    leaderboardId: string,
    invalidationKey?: string,
    length?: number,
  ) => Observable<TLeaderboardSummaryAndLeaders>;
  onUserSelected: (user: TFirebaseUser) => void;
  onChallengeSelected: (group: TFirebaseChallengeGroup) => void;
  onVipChallengeSelected: (userA: TFirebaseUser, userB: TFirebaseUser) => void;
  onNewChallengeSelected: () => void;
  onGoToLeaderboard: () => void;
  onGoToMultiGameLeaderboard: (leaderboardId: string) => void;
  onVendorSelected: (vendor: IVendorDetails) => void;
  onGoToGame: () => void;
}

export const Latest: React.FC<IProps> = (props) => {
  return (
    <div className={applyPropClassName('latest__container', props)}>
      <div className="latest__background-image">
        <LeaderboardImage
          media={props.leaderboardConfig.media}
          MediaComponent={MediaView as ComponentType<{media: TMediaEntry}>}
        />
      </div>
      <div className="latest__foreground-container">
        <div className="latest__leaderboard-container">
          <LeaderboardSummary
            maxLeaderboardLength={3}
            title={props.leaderboardConfig.shortName}
            subTitle={`${props.compSummary.roundName} ∙ ${props.compSummary.name}`}
            primaryColor={props.compSummary.primaryColor}
            status={props.status}
            players={props.players}
            prizes={props.leaderboardConfig.prizes}
            vendorImageUrl={props.compSummary.logo.image?.url}
            currentPlayer={props.currentPlayer}
            currentUser={props.currentUser}
            currentUserEntry={props.currentUserEntry}
            entryStatus={props.entryStatus}
            entriesCount={props.entriesCount}
            compQuestions={props.compQuestions}
            vendor={props.compSummary.vendor}
            starts={props.compSummary.starts}
            followingMap={props.followingMap}
            MediaComponent={MediaView as ComponentType<{media: TMediaEntry}>}
            isLive={props.compSummary.liveScore?.isLive}
            getUserById={props.getUserById}
            onUserSelected={props.onUserSelected}
            onGoToGame={props.onGoToGame}
            onGoToLeaderboard={props.onGoToLeaderboard}
          />
        </div>
        {(props.challengesWithUnreads.length > 0 || props.vips.length >= 2) && (
          <SectionSubTitle title="" subTitle="Challenges" />
        )}
        <div className="latest__challenges-container">
          {props.challengesWithUnreads.map((c) =>
            c.challenge.users.length === 2 ? (
              <LazyChallengeButton
                key={c.challenge.id}
                result={c.challenge.result}
                currentUser={props.currentUser}
                challengerId={getChallengerId(c.challenge)}
                challengedId={getChallengedId(c.challenge)}
                badge={c.badge}
                getUserById={props.getUserById}
                onSelected={() => props.onChallengeSelected(c.challenge)}
              />
            ) : null,
          )}
          {props.vips.length >= 2 &&
            props.vips
              .reduce(function (result, value, index, array) {
                if (index % 2 === 0) {
                  result.push(array.slice(index, index + 2));
                }
                return result;
              }, [] as TFirebaseUser[][])
              .filter((pair) => pair.length === 2)
              .map((pair, index) => (
                <ChallengeButton
                  key={index}
                  currentUser={props.currentUser}
                  challenger={pair[0]}
                  challenged={pair[1]}
                  showLabels={false}
                  badge={0}
                  onSelected={props.onVipChallengeSelected}
                />
              ))}
        </div>
        {props.compSummary.leaderboards
          .filter((l) => l.type !== LeaderboardType.INGAME)
          .map((l) => (
            <div key={l.id} className="latest__multigameleaderboard-container">
              <MultiGameLeaderboardSummary
                leaderboardId={l.id}
                invalidationKey={props.lastVerifiedCompId}
                vendorImageUrl={props.compSummary.logo.image?.url}
                primaryColor={props.compSummary.primaryColor}
                currentUser={props.currentUser}
                followingMap={props.followingMap}
                minLeaderboardLength={0}
                MediaComponent={
                  MediaView as ComponentType<{media: TMediaEntry}>
                }
                getUserById={props.getUserById}
                getMultiGameLeaderboardSummaryById={
                  props.getMultiGameLeaderboardSummaryById
                }
                onUserSelected={props.onUserSelected}
                onGoToLeaderboard={() => props.onGoToMultiGameLeaderboard(l.id)}
              />
            </div>
          ))}
        <SectionSubTitle title="" subTitle="Results" />
        <div className="latest__vendor-container">
          <CompVendorListItem
            vendor={props.vendor}
            onSelected={props.onVendorSelected}
          />
        </div>
      </div>
    </div>
  );
};
